import * as env from '@/config/env';
import { apiRequest } from '@/services';
import { mongodbCrudOperations } from '@/utils/MongoQueries/crudOperations/crudOperations';
import { dbCollections } from '@/utils/FirebaseCollections';

const addSubscription = (companyIdRoute, response) => {
    apiRequest("post", env.ADD_DEFAULT_SUBSCRIPTION, {companyId : companyIdRoute, userId : response.data.statusText._id}).catch((error) => {
        console.error(error,"ERROR");
    });
};

const displayUpgrade = (selectedCompany, chargeBeePriceData, companyOwner) => {
    if (selectedCompany && selectedCompany.SubcriptionId && selectedCompany.SubcriptionId !== '' && chargeBeePriceData.length && companyOwner.isCurrentUser)  {
        let obj = {
            type: 'findOne',
            collection: dbCollections.SUBSCRIPTIONS,
            global:true,
            data: [
                {
                    subscriptionId: selectedCompany.SubcriptionId
                },
            ]
        }
        mongodbCrudOperations(obj).then((response)=>{
            let selectedPlan = response.subscription_items.find((x)=> x.item_type == 'plan')?.item_price_id || '';
            if (selectedPlan) {
                let currPlan = chargeBeePriceData.find((x)=> x.itemPriceArray.find((x)=> x.id == selectedPlan) || '') || '';
                if (currPlan) {
                    return currPlan.defaultSubscribe;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error)=>{
            console.error(error);
            return false;
        })
    } else {
        return false;
    }
};

const paymentInit = () => {
    const script = document.createElement('script');
    script.src = "https://js.chargebee.com/v2/chargebee.js"; // Get the src from plugin options
    script.async = true; // Optional: set the script to load asynchronously
    script.onload = () => {
        window.Chargebee.init({
            site: env.CHARGEBEE_SITE,
            publishableKey: env.CHARGEBEE_PUBLISHABLE_KEY
        });
        console.log("Loaded Chargebee Payment")
    }
    // Append the script to the head
    document.head.appendChild(script);
}

export default {
    paymentInit,
    addSubscription,
    displayUpgrade
};
