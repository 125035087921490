import * as mongoQuery from "@/utils/MongoQueries/crudOperations/crudOperations";
import { dbCollections } from "@/utils/FirebaseCollections";
export function customField() {
    // deleteMilestoneHelper
    function tabRouteHelper(){
        return [{
            label: "Custom Field Manager",
            to: { name: "Custom-Fields" },
            icon: require("@/assets/images/svg/WorkspaceSettingsInactive.svg"),
            isVisible: true,
            permissions: ['settings.settings_custom_field'],
            activeIcon: require("@/assets/images/svg/WorkspaceSettings.svg")
        }];
    }
    function projectAlianApp(){
        return [''];
    }
    function setfinalCustomFieldsArray ({commit}) {
        return new Promise((resolve, reject) => {
            try {
                const getQuery = {
                    type: "find",
                    collection: dbCollections.CUSTOM_FIELDS,
                    data: []
                }
                mongoQuery.mongodbCrudOperations(getQuery)
                .then((res) => {
                    commit("mutateFinalCustomFields", {
                        data: res || [],
                        op: "inital",
                    });
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
            } catch (error) {
                reject(error);
            }
    
        })
    }
    function setCustomFieldsArray ({commit}) {
        return new Promise((resolve, reject) => {
            try {
                let getQuery = {
                    type : "find",
                    collection : dbCollections.CUSTOM_FIELDS,
                    data: [],
                    global: true
                }
                mongoQuery.mongodbCrudOperations(getQuery)
                    .then((res) => {
                        if (!(res && res.length)) {
                            resolve();
                            return;
                        }
                        commit("mutateCustomFields", res)
                        resolve(res);
                })
                .catch((error) => {
                    reject(error);
                    console.error("ERROR in get Customfields: ", error);
                })
            } catch (error) {
                reject(error);
            }
    
        })
    }
    function isCustomFields() {
        return true;
    }
    return {
        tabRouteHelper,
        projectAlianApp,
        setfinalCustomFieldsArray,
        setCustomFieldsArray,
        isCustomFields
    }
}